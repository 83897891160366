:root {
  --borderColor: #c9d4d9;
  --textColor: #272a2d;
  --primaryTextColor: #1169f7;
  --secondaryTextColor: #546c79;
  --primaryColor: #589cfd;
  --menuColor: #f6f7f7;
}

$colors: (
  'green': #04c877,
  'red': #ff4d4f,
  'yellow': #e1a500,
);

@each $color-name, $color-value in $colors {
  .c-#{$color-name} {
    color: $color-value;
  }
}
