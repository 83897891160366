//for fallback use case
@import '@/scss/abstracts/variables';
@import '@/scss/abstracts/mixins';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500&display=swap');

$browser-content: 16; //base

html {
  font-size: 100%;
  font-family: 'Microsoft YaHei UI', sans-serif;
  @include media-breakpoint-down(md) {
    font-size: calc(12 / $browser-content * 100%);
  }
}

body,
#root {
  font-family: 'Microsoft YaHei UI', 'Noto Sans SC', sans-serif;
}

$headings: (
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
);

@each $name,
$size in $headings {

  #{$name},
  .#{$name} {
    // margin: 0;
    // padding: 0;
    font-family: 'Microsoft YaHei UI Bold', 'Noto Sans SC', sans-serif;
  }
}

a {
  font-family: 'Microsoft YaHei UI', sans-serif;
  color: black;
}