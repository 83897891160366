%constrain {
  max-width: calc(1330px + var(--spacing) * 2);
  padding-inline: var(--spacing);
  margin: auto;
}

$typographywysiwyg: (
  h1: (
    font-size: px_em(26),
    line-height: px_em(48, 26),
    letter-spacing: px_em(0.13, 26),
    mobile-font-size: px_em(22, 16),
    mobile-line-height: px_em(41, 22),
  ),
  h2: (
    font-size: px_em(24),
    line-height: px_em(45, 24),
    letter-spacing: px_em(0.12, 24),
    mobile-font-size: px_em(20, 16),
    mobile-line-height: px_em(37, 20),
  ),
  h3: (
    font-size: px_em(22),
    line-height: px_em(41, 22),
    letter-spacing: px_em(0.11, 22),
    mobile-font-size: px_em(18, 16),
    mobile-line-height: px_em(34, 18),
  ),
  h4: (
    font-size: px_em(20),
    line-height: px_em(37, 20),
    letter-spacing: px_em(0.1, 20),
    mobile-font-size: px_em(16, 16),
    mobile-line-height: px_em(30, 16),
  ),
  h5: (
    font-size: px_em(18),
    line-height: px_em(34, 18),
    letter-spacing: px_em(0.09, 18),
    mobile-font-size: px_em(16, 16),
    mobile-line-height: px_em(30, 16),
  ),
  h6: (
    font-size: px_em(16),
    line-height: px_em(32, 16),
    letter-spacing: px_em(0.08, 16),
    mobile-font-size: px_em(16, 16),
    mobile-line-height: px_em(30, 16),
  ),
  p: (
    font-size: px_em(16),
    line-height: var(--lineHeight),
    letter-spacing: var(--letterSpacing),
    mobile-font-size: px_em(16, 16),
    mobile-line-height: px_em(30, 16),
  ),
  blockquote: (
    font-size: px_em(16),
    line-height: var(--lineHeight),
    letter-spacing: var(--letterSpacing),
    mobile-font-size: px_em(14, 16),
    mobile-line-height: px_em(26, 14),
  ),
);

%wysiwygReset {
  --lineHeight: 30px;
  --letterSpacing: 0.08px;
  --baseFontSize: 16px;
  font-size: var(--baseFontSize);
  body {
    font-family: inherit;
    font-size: var(--baseFontSize);
    letter-spacing: var(--letterSpacing);
    line-height: var(--lineHeight);
  }
  img,
  video {
    max-width: 100%;
    height: auto;
  }
  video {
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: var(--lineHeight);
    margin: revert;
    margin-block: unset;
    & + p {
      margin-top: px_em(8);
    }
  }
  @each $typography, $values in $typographywysiwyg {
    @if $typography == 'blockquote' {
      #{$typography} p {
        font-size: map-deep-get($typographywysiwyg, #{$typography}, 'font-size');
        line-height: map-deep-get($typographywysiwyg, #{$typography}, 'line-height');
        letter-spacing: map-deep-get($typographywysiwyg, #{$typography}, 'letter-spacing');
        margin-block: unset;
        @include media-breakpoint-down(md) {
          font-size: map-deep-get($typographywysiwyg, #{$typography}, 'mobile-font-size');
          line-height: map-deep-get($typographywysiwyg, #{$typography}, 'mobile-line-height');
        }
      }
    } @else {
      #{$typography} {
        font-size: map-deep-get($typographywysiwyg, #{$typography}, 'font-size');
        line-height: map-deep-get($typographywysiwyg, #{$typography}, 'line-height');
        letter-spacing: map-deep-get($typographywysiwyg, #{$typography}, 'letter-spacing');
        @include media-breakpoint-down(md) {
          font-size: map-deep-get($typographywysiwyg, #{$typography}, 'mobile-font-size');
          line-height: map-deep-get($typographywysiwyg, #{$typography}, 'mobile-line-height');
          letter-spacing: normal;
        }
      }
    }
  }

  blockquote {
    $borderColor: #4d35fc;
    position: relative;
    border-left: 1px solid rgba($borderColor, 0.2);
    margin-block: px_em(40);
    margin-left: 0;
    padding-left: px_em(16);
    color: #666666;
    @include media-breakpoint-down(md) {
      padding-left: px_em(16, 14);
    }
    &::before {
      width: 5px;
      height: 20px;
      background: var(--primaryTextColor);
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -0.5px;
      transform: translate(-50%, -50%);
    }
  }

  strong {
    display: inline-block;
  }
  ol,
  ul {
    list-style: revert;
    line-height: var(--lineHeight);
    letter-spacing: var(--letterSpacing);
    padding-inline-start: px_rem(24);
    li {
      margin-block: px_rem(26);
    }
  }
  ul {
    ul ul ul {
      list-style-type: disc;
    }
  }
  ol {
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: auto;
        }
      }
    }
  }
  img {
    height: auto;
    max-width: 100%;
  }
  a {
    color: var(--primaryTextColor);
    text-decoration: underline;
  }
  p {
    margin-block: px_em(40);
    @include media-breakpoint-down(md) {
      margin-block: px_em(16);
    }
  }
  pre {
    text-wrap: balance;
  }
  table {
    border-color: #e5e5e5;
    td {
      padding: px_em(16);
      font-size: var(--baseFontSize);
      letter-spacing: var(--letterSpacing);
      line-height: var(--lineHeight);
    }
  }
}
