* {
  box-sizing: border-box;
}

//Abstract
// @import "abstracts/screens";
@import 'abstracts/variables';
@import 'abstracts/fonts';
@import 'abstracts/colors';
@import 'abstracts/functions';

@import 'abstracts/mixins';
@import 'abstracts/placeholder';
@import 'abstracts/helpers';
// @import "abstracts/placeholder";
// @import "abstracts/keyframes";

//Base
// @import "base/reset";
// @import "base/base";
// @import "base/typography";

//Components
@import 'components/buttons';
// @import "components/icons";
// @import "components/drawer";
@import 'components/tabs';
@import 'components/modal';

//Layout

//Pages

body {
  margin: 0;
}

p {
  margin: 0;
}

button {
  margin: 0;
  border: none;
  padding: 0;
  background-color: transparent;
  padding: 0;
  padding-block: 0;
}

.link {
  color: var(--primaryTextColor);
  cursor: pointer;
  &:hover {
    color: #69b1ff;
  }
}
.tox-tinymce-aux {
  z-index: 500 !important;
}
.tox :not(svg):not(rect) {
  .tox-dialog__body-nav {
    display: none;
  }
}
.wysiwyg {
  @extend %wysiwygReset;
}

div[aria-label='Sharing Controls'] {
  transform: translateY(60px);
}

button[aria-label='More'] {
  display: none;
}

.antDropdownPadding{ 
  .ant-select-item {
    padding: 0 !important;
    align-items: center;
  }
}
